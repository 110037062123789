import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import {
  CssBaseline,
  Typography,
  makeStyles,
  Container,
  Button,
  Hidden
} from "@material-ui/core"


import tos_pt from "../tos/tos_pt.pdf"
import tos_es from "../tos/tos_es.pdf"
import tos_en from "../tos/tos_en.pdf"
import pp_es from "../tos/pdp_es.pdf"
import pp_en from "../tos/pdp_en.pdf"
import pp_pt from "../tos/pdp_pt.pdf"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 8,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: 8,
  },
}))

const Tos = ({ intl }) => {
  const classes = useStyles()
  const currentLocale = intl.locale
  return (
    <Layout>
      <SEO title="TOS" />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: "tos" })}
          </Typography>

          <div>
            <br/>
            {currentLocale === "en" ? (
              <a href={tos_en} rel="noreferrer" target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: "boton_tos" })}
                </Button>
              </a>
            ) : currentLocale === "pt" ? (
              <a href={tos_pt} rel="noreferrer" target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: "boton_tos" })}
                </Button>
              </a>
            ) : (
              <a href={tos_es} rel="noreferrer" target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: "boton_tos" })}
                </Button>
              </a>
            )}
            <br/>

          </div>
          <br/>
          <br/>

          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: "politicaprivacidad" })}
          </Typography>
          <div>
          <br/>

            {currentLocale === "en" ? (
              <a href={pp_en} rel="noreferrer" target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: "boton_tos" })}
                </Button>
              </a>
            ) : currentLocale === "pt" ? (
              <a href={pp_pt} rel="noreferrer" target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: "boton_tos" })}
                </Button>
              </a>
            ) : (
              <a href={pp_es} rel="noreferrer" target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: "boton_tos" })}
                </Button>
                {/*                 <img
                  width={40}
                  style={{ margin: 20 }}
                  src={esBandera}
                  alt="es"
                /> */}
              </a>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default injectIntl(Tos)
